import { deleteCartItem, patchCartItem, postCartItem } from '~/composables/queries/useCartItem'
import type { CartItem } from '~/src/types/CartItem'
import { getCart } from '~/composables/queries/useCarts'
import { useUser } from '~/composables/data/useUser'
import { getIdFromIri } from '~/utils/resource'
import type { KeyValueT } from '~/src/types/KeyValue'

export function useCart (organizationId?: ComputedRef<string | undefined>) {
  const { user } = useUser()

  const id = computed(() => {
    if (!user.value) { return '' }
    if (!user.value.currentCart) { return '' }
    return getIdFromIri(user.value.currentCart as unknown as string)
  })

  const params = reactive({
    params: {
      path: { id },
      query: {
        organizationId
      }
    }
  })
  const { data } = getCart(params)

  const items = computed<CartItem[]>(() => {
    if (!data.value) { return [] }
    const sortedItems = [...data.value.items]
    return sortedItems.sort(function (a: CartItem, b: CartItem) {
      if (a.logInfo.name < b.logInfo.name) { return -1 }
      if (a.logInfo.name > b.logInfo.name) { return 1 }
      return 0
    });
  })
  const totalQty = computed(() => items.value.reduce((total, item: CartItem) => total + item.logInfo.qty, 0))
  const productItemMap = computed(() => {
    return items.value.reduce((acc: KeyValueT, item: CartItem) => ({
      ...acc,
      [item.product['@id']]: item.logInfo.id
    }), {} as KeyValueT)
  })
  const total = computed(() => data.value ? +data.value.total : 0)
  const subTotal = computed(() => data.value ? +data.value.subTotal : 0)
  const clientFee = computed(() => data.value ? +data.value.clientFee : 0)
  const tax = computed(() => data.value ? +data.value.tax : 0)
  const remainingBalance = computed(() => data.value ? +data.value.remainingBalance : 0)

  const { mutateAsync: cartAddAction, isPending: isAddPending } = postCartItem()
  const { mutateAsync: cartUpdateAction, isPending: isUpdatePending } = patchCartItem()
  const { mutateAsync: cartDeleteAction, isPending: isDeletePending } = deleteCartItem()

  const isPending = computed(() => isAddPending.value || isUpdatePending.value || isDeletePending.value)

  const add = async (iri: string, qty: number = 1) => {
    if (items.value.length) {
      const item = items.value.find((item: CartItem) => item.product['@id'] === iri)

      if (item) {
        await cartUpdateAction({
          params: { path: { id: item.logInfo.id } },
          body: { qty: item.logInfo.qty + qty }
        })
        return
      }
    }

    await cartAddAction({
      body: { product: iri as unknown as Record<string, never>, qty },
      invalidateUser: !user.value?.currentCart
    })
  }

  const { add: addNotification } = useNotification()

  const update = async (id: string, qty: number) => {
    try {
      await cartUpdateAction({
        params: { path: { id } },
        body: { qty }
      })
    } catch (e) {
      addNotification({
        content: 'Unable to change the quantity. Please, try again later...'
      })
      console.error(e)
    }
  }

  const remove = async (id: string) => {
    try {
      await cartDeleteAction({ params: { path: { id } } })
    } catch (e) {
      addNotification({
        content: 'Unable to delete an item from the cart. Please, try again later...'
      })
    }
  }

  const updateQty = async (iri: string, quantity: number) => {
    if (!Object.hasOwn(productItemMap.value, iri) && quantity !== 0) {
      await add(iri, quantity)
      return
    }
    if (quantity === 0 && Object.hasOwn(productItemMap.value, iri)) {
      await remove(productItemMap.value[iri])
      return
    }
    await update(productItemMap.value[iri], quantity)
  }

  return {
    items,
    totalQty,
    productItemMap,
    total,
    subTotal,
    clientFee,
    tax,
    remainingBalance,
    add,
    updateQty,
    isPending
  }
}
